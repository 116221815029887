@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Roboto:wght@300;700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 80px;
  pointer-events: none;
}

.App-header {
  background-color: #fff;
  width: 100%;
  border-bottom: solid 1px #ddd;
}

.App-title {
  font-size: 2em;
  font-family: 'Lobster';
  font-weight: 700;
  margin: 20px;
  color: #444;
}

.App-subtitle {
  font-size: 0.7em;
  font-family: 'Roboto';
  font-weight: 700;
  margin: 20px;
  color: #444;
  text-align: left;
  border-bottom: 1px solid #888;
  padding: 4px;
  color: #444;
  text-transform: uppercase;
}

.App-body {
  background-color: #fcfcfc;
}

.App-card-regular {
  height: 220px;
  max-width: 250px;
  background-color: #fff;
  border: solid 1px #ddd;
  border-radius: 5px;
  margin: 20px 10px;
  padding: 10px;
  display: inline-block;
  cursor: pointer;
}

.App-card-regular-title {
  font-size: 1em;
  font-family: 'Roboto';
  font-weight: 700;
  color: #282828;
  text-align: left;
  padding: 5px 0px;
}

.App-card-regular-subtitle {
  font-size: 0.7em;
  font-family: 'Roboto';
  font-weight: 300;
  color: #282828;
  text-align: left;
}

.App-link {
  color: #61dafb;
}

.video-container {
  border: solid 0px;
}

.overlay {
  min-width: 100%;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.hide {
  display: none;
}

.video-player {
  background-color: #fff;
  padding: .3em;
  border-radius: 3px;
  z-index: 2;
  max-width: 100%;
}

.photo-viewer {
  background-color: #fff;
  padding: .3em;
  border-radius: 3px;
  z-index: 2;
  max-width: 100%;
}

.video-close {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  padding: 0px 10px 10px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.photo-close {
  font-size: 12px;
  font-weight: bold;
  text-align: right;
  padding: 0px 10px 10px 10px;
  text-transform: uppercase;
  cursor: pointer;
}

.center {
  position: fixed;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}

#mainVideo {
  max-width: 100%;
  max-height: 100%;
}

.previewImage {
  max-width: 240px;
  max-height: 240px;
}

#mainPhoto {
  max-width: 800px;
  max-height: 500px;
}

@media only screen and (max-width: 1024px) {
  .center {
    position: fixed;
    left: 0%;
    top: 50%;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    transform: translate(0%, -50%);
    }
  
}